import React from 'react';

import Company from '../Company'
import RoleDescription from '../RoleDescription'
import CompanyLogoCb from '../../../images/LogoCb'

export default () => (
    <div className="row item work-item">
        <Company
            data={{
                timeRange: "10/2017 - 2019",
                company: {
                    name: "cleverbridge AG",
                    url: "http://www.cleverbridge.com",
                    location: "Köln"
                },
                project: {
                    title:
                        'Greenfield Project "my-Account (Customer-Self-Service)"',
                    details: [
                        "(F-Secure)[https://my.f-secure.com] specific E-Commerce Customer-Self-Service",
                        "supporting individual buiness / price model and product settings",
                        "connected / integrated identity management (O-Auth)",
                        "supporting various configurable marketing options (coupon codes, landing pages, discounting, segmentation, ...)",
                        "affiliate management",
                        "subscription management"
                    ],
                },
            }}
        >
            <CompanyLogoCb style={{
                width: "20%",
                height: "20%",
                float: "right"
            }} />
        </Company>

        <div className="work-description">
            <ul>
                <RoleDescription
                    roleTitle="Role as <b>Fullstack Developer</b> in a SCRUM-Team:"
                    roleDescription='As a Teamlead, taking care about requirements management, talking to clients and stakeholder and developing and evolving the platform.'
                    tags={[
                        "PHP",
                        "Symfony 2.8",
                        "Twig",
                        "Capifony",
                        "Composer",
                        "Docker",
                        "Kubernetes",
                        "Memcached",
                        "MySQL",
                        "AWS",
                        "Angular",
                        "SCSS"
                    ]}
                />
                <RoleDescription
                    roleTitle="Role as <b>Projectmanager</b> IdM migration:"
                    roleDescription='Coordinating internationally multiple parties, client requirements'
                    tags={[
                        "PHP",
                        "Symfony 2.8",
                        "Twig",
                        "O-Auth",
                        "OneId"
                    ]}
                />
            </ul>
        </div>
    </div>
)

import React from 'react';

export default ({ skill, level }) => (
    <div className="bars">
        <ul className="skills">
            <li>
                <span className={`bar-expand level${level}`}>
                </span><em>{ skill }</em>
            </li>
        </ul>
    </div>
);

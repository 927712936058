import React from "react"
import { IconContext } from 'react-icons'
import { FaLinkedin, FaGithub, FaXing, FaSkype } from 'react-icons/fa'

import WelcomeTyping from '../components/WelcomeTyping'
import Menu from '../components/Menu'

export default () => (
  <header id="home">
    <Menu />
    <div className="row banner">
      <div className="banner-text">
        <WelcomeTyping />
        <hr />
        <ul className="social">
          <IconContext.Provider value={{ className: 'social-media-icons' }}>
            <li>
              <a href="https://www.linkedin.com/in/nico-schunke-bb73364b" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            </li>
            <li>
              <a href="https://github.com/nico-schunke" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
            </li>
            <li>
              <a href="https://www.xing.com/profile/Nico_Schunke" target="_blank" rel="noopener noreferrer"><FaXing /></a>
            </li>
            <li>
              <a href="skype:nico.schunke?userinfo" target="_blank" rel="noopener noreferrer"><FaSkype /></a>
            </li>
          </IconContext.Provider>
        </ul>
      </div>
    </div>

    <p className="scrolldown">
      <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
    </p>
  </header>
)

import React from 'react';

import Skills from '../components/Skills';
import Work from '../components/work';
import Education from '../components/Education';

export default () => (
  <section id="resume">
    <Skills />
    <Work />
    <Education />
  </section>
);

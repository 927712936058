import React from 'react';

export default ({ tags }) => (
    <>
    {
        tags.map((tag) =>
            <><span>{tag}</span><em></em></>
        )
    }
    </>
);

import React, { Component } from 'react';
import ReactGA from 'react-ga';

import Layout from '../components/Layout';
import Landing from '../sections/Landing';
import About from '../sections/About';
import Resume from '../sections/Resume';
// import Portfolio from './components/Portfolio';
// import Testimonials from  './components/Testimonials';
// import ContactUs from './components/ContactUs';
import Footer from '../sections/Footer';

class App extends Component {
  componentDidMount() {
    ReactGA.initialize('UA-23552344-1');
    ReactGA.pageview('/abc');
  }

  render() {
    return (
      <Layout>
        <div className="App">
          <Landing />
          <About />
          <Resume />
          {/* <Portfolio resumeData={resumeData}/>
          <Testimonials resumeData={resumeData}/> 
          <ContactUs resumeData={resumeData}/>  */}
          <Footer />
        </div>
      </Layout>
    );
  }
}

export default App;
import React from 'react';

import Company from '../Company'
import RoleDescription from '../RoleDescription'
import CompanyLogoMs from '../../../images/LogoMs'

export default () => (
    <div className="row item work-item" style={{ borderBottom: 'none' }}>
        <Company
            data={{
                timeRange: "01/2014 - 05/2015",
                company: {
                    name: "meinestadt.de GmbH",
                    url: "http://www.meinestadt.de",
                    location: "Siegburg / Köln"
                },
                project: {
                    title:
                        'Portal to allow Companies to create Job Market Advertisements (Job-Shop)',
                    details: [
                        "supporting different price models and advertisement types",
                        "previewing and bookmarking",
                        "customer accounts, which gives tranparency about advertisement in the past and bookmarked for future releases",
                        "emailing and invoicing"
                    ],
                },
            }}
        >
            <CompanyLogoMs style={{
                width: "20%",
                height: "20%",
                float: "right"
            }} />
        </Company>
        <div className="work-description">
            <ul>
                <RoleDescription
                    roleTitle="Role as <b>Fullstack-Developer</b>:"
                    roleDescription="Enhancing an early state product beeing in productive use. Realizing Design, UX and SEO requirements. Integrated the project into the existing companies IT infrastructure and business logic."
                    tags={[ 
                        'PHP',
                        'Symfony',
                        'Twig',
                        'HTML',
                        'CSS',
                        'Apache',
                        'Oracle',
                        'PL/SQL'
                    ]}
                />
            </ul>
        </div>
    </div>
)

import React from "react"
import { Link } from 'react-scroll'

import ProfilImageNico from '../images/ProfileNico'

const style = {
   borderRadius: '5%'
};

export default () => (
   <section id="about">
      <div className="row">
         <div className="three columns">
            <ProfilImageNico style={style} />
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>
               I am a passionate web developer, always looking for new and exciting projects. I have already met many industries, such as Agencies, logistics, education, e-commerce, security, ...
               </p>
            <p>
               I have often been active internationally, which is why english speaking feels almost natural to me. Besides that, I have very good communication skills to all participating parties, which enables me to express very technical things in human understanding. :-)
               </p>
            <p>
               In recent years, my focus has been mainly on advancing large complex systems and requirements from a system architecture and scalability perspective.
               </p>
            <p>
               I would be happy to help you identify requirements and lead them to project success. <Link to="contact" spy={true} smooth={true} duration={500}>Contact me</Link> to hire.
               </p>
            <div className="row">
               <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
                     <span>Nico Schunke</span>
                     <br></br>
                     <span>53332 Bornheim, NRW</span>
                     <br></br>
                     <span><a href="mailto:nico.schunke@softwise.de" rel="noreferrer noopener">nico.schunke@softwise.de</a></span>
                     <br></br>
                     <span><a href="http://www.softwise.de" target="_blank" rel="noreferrer noopener">http://www.softwise.de</a></span>
                  </p>
               </div>
            </div>
         </div>
      </div>
   </section>
)
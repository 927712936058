import React, { Component } from 'react';

import SkillItem from './SkillItem';
import SkillSet from './SkillSet';

export default class Skills extends Component {
    render() {
        return (
            <div className="row skill">
                <div className="two columns header-col">
                    <h1><span>Skills</span></h1>
                </div>
                <div className="four columns">
                    <SkillSet description="Architecture / Patterns">
                        <SkillItem skill="Microservices" level="100" />
                        <SkillItem skill="Self-Contained-Systems / Verticals" level="100" />
                        <SkillItem skill="DDD" level="90" />
                        <SkillItem skill="Event-Driven" level="90" />
                        <SkillItem skill="Continious Delivery" level="80" />
                        <SkillItem skill="micro-frontends" level="80" />
                        <SkillItem skill="BDD" level="70" />
                        <SkillItem skill="Serverless" level="60" />
                    </SkillSet>

                    <SkillSet description="Operating">
                        <SkillItem skill="Docker" level="90" />
                        <SkillItem skill="RabbitMq" level="80" />
                        <SkillItem skill="Logging" level="80" />
                        <SkillItem skill="Metrics" level="80" />
                        <SkillItem skill="CDN" level="80" />
                        <SkillItem skill="Kubernetes" level="70" />
                        <SkillItem skill="Nginx" level="70" />
                        <SkillItem skill="Kafka" level="70" />
                        <SkillItem skill="Apache" level="60" />
                    </SkillSet>
                </div>
                <div className="two columns"></div>
                <div className="four columns">
                    <SkillSet description="Backend">
                        <SkillItem skill="Node.js" level="100" />
                        <SkillItem skill="PHP" level="100" />
                        <SkillItem skill="SQL" level="70" />
                        <SkillItem skill="NoSQL" level="70" />
                        <SkillItem skill="Go" level="40" />
                        <SkillItem skill="Java" level="30" />
                    </SkillSet>

                    <SkillSet description="Frontend">
                        <SkillItem skill="React.js" level="80" />
                        <SkillItem skill="HTML" level="70" />
                        <SkillItem skill="CSS" level="60" />
                        <SkillItem skill="Angular" level="30" />
                        <SkillItem skill="Vue.js" level="30" />
                    </SkillSet>
                </div>
            </div>
        )
    }
}

import React from 'react';

export default () => (
  <div className="row education">
    <div className="three columns header-col">
      <h1><span>Education</span></h1>
    </div>
    <div className="nine columns main-col">
      <div className="row item">
        <div className="twelve columns">
          <h3>Berufsakademie Eisenach</h3>
          <p className="info">
            Dipl. Ing. für Informations- und Kommunikationstechnologien
              <span>&bull;</span> <em className="date">2005 - 2008</em></p>
        </div>
      </div>
      <div className="row item">
        <div className="twelve columns">
          <h3>Fachschule für Bau, Wirtschaft und Verkehr</h3>
          <p className="info">
            Staatlich geprüfter technischer Assistent für Informatik
            <span>&bull;</span> <em className="date">2002 - 2005</em></p>
        </div>
      </div>
    </div>
  </div>
);

import React from 'react';

import Company from '../Company'
import RoleDescription from '../RoleDescription'
import CompanyLogoCb from '../../../images/LogoCb'

export default () => (
    <div className="row item work-item">
        <Company
            data={{
                timeRange: "06/2015 - 09/2017",
                company: {
                    name: "cleverbridge AG",
                    url: "http://www.cleverbridge.com",
                    location: "Köln"
                },
                project: {
                    title:
                        'Greenfield Project "E-Commerce Subscription Management Platform"',
                    details: [
                        "help companies to sell digital goods on a subscription base globally",
                        "supporting individual buiness / price model and product settings",
                        "customer-self-services and identity management",
                        "reporting to give business insisghts and forecasts to reduce churn",
                        "supporting various payment methods, invocing and taxation",
                    ],
                },
            }}
        >
            <CompanyLogoCb style={{
                width: "20%",
                height: "20%",
                float: "right"
            }} />
        </Company>
        <div className="work-description">
            <ul>
                <RoleDescription
                    roleTitle="Role as <b>Architecture-Owner</b>:"
                    roleDescription="Evolving the Architecure of the platform in a modern Web-Tech-Stack and creating an architectural outline from makro to micro Architecture to fulfill business needs, allowing Business-Teams flexibility and autonomity."
                    tags={[
                        "Micro-Service",
                        "Micro-Frontend",
                        "self-contained-Systems",
                        "Event-Driven",
                        "CI/CD",
                        "12-Factor"
                    ]}
                />
                <RoleDescription
                    roleTitle="Role as <b>Fullstack Developer</b> in a SCRUM-Team:"
                    roleDescription='Taking care about Identity Management (Authentication & Authorization), Emailing, Invoicing, Webhooks, Client-Management and Signup.'
                    tags={[
                        "Node.js",
                        "React",
                        "Docker",
                        "CQRS",
                        "Event-Sourcing",
                        "OpenId-Connect",
                        "Kafka",
                        "RabbitMq",
                        "Reddis",
                        "DynamoDB",
                        "Scala"
                    ]}
                />
                <RoleDescription
                    roleTitle="Role as <b>Stakeholder</b> of the PaaS Team:"
                    roleDescription='Proritizing Infrastructure and Platform services, based on Business and Developer needs.'
                    tags={[
                        "AWS",
                        "Kubernetes",
                        "CI/CD",
                        "Jenkins",
                        "Nexus",
                        "ELK",
                        "Prometheus",
                        "Grafana",
                        "Gitlab"
                    ]}
                />
            </ul>
        </div>
    </div>
)

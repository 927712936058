import React from "react"
import { IconContext } from 'react-icons'
import { FaLinkedin, FaGithub, FaXing, FaSkype, FaChevronUp } from 'react-icons/fa'
import { Link } from 'react-scroll'

export default () => (
  <footer>
    <div className="row">
      <div className="twelve columns">
        <ul className="social-links">
          <IconContext.Provider value={{ className: 'social-media-icons' }}>
            <li>
              <a href="https://www.linkedin.com/in/nico-schunke-bb73364b" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            </li>
            <li>
              <a href="https://github.com/nico-schunke" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
            </li>
            <li>
              <a href="https://www.xing.com/profile/Nico_Schunke" target="_blank" rel="noopener noreferrer"><FaXing /></a>
            </li>
            <li>
              <a href="skype:nico.schunke?userinfo" target="_blank" rel="noopener noreferrer"><FaSkype /></a>
            </li>
          </IconContext.Provider>
        </ul>
      </div>
      <div id="go-top"><Link to="home" spy={true} smooth={true} duration={500}><FaChevronUp /></Link></div>
    </div>
  </footer>
)
import React, { Component } from 'react'
import PropTypes from "prop-types"
import AnimateHeight from 'react-animate-height'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

class SkillSet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 170,
            collapsed: true,
            skills: props.children
        }
    }

    toggle = () => {
        const { collapsed } = this.state;

        this.setState({
            height: collapsed ? 'auto' : 170,
            collapsed: collapsed ? false : true
        });
    };

    render() {
        return (
            <div className="twelve columns">
                <p>
                    { this.props.description }
                </p>
                <AnimateHeight
                    duration={500}
                    height={this.state.height} 
                >
                    { this.state.skills }
                </AnimateHeight>
                <button onClick={this.toggle}>
                    {
                        this.state.collapsed ? <FaChevronDown /> : <FaChevronUp />
                    }
                </button>
            </div>
        )
    }
}

SkillSet.propTypes = {
    children: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired
}

export default SkillSet

import React, { Component } from "react"
import Typist from 'react-typist';

class WelcomeTyping extends Component {
    state = {
      renderMsg: false,
    }
  
    onHeaderTyped = () => {
      this.setState({ renderMsg: true });
    }
  
    render() {
      return (
        <div id="welcome-typing">
          <h1>
            <Typist
              avgTypingSpeed={40}
              onTypingDone={this.onHeaderTyped}
              cursor={{ show: false }}
            >
              Hello!
              <Typist.Delay ms={1250} />
              &nbsp;I am Nico Schunke.
  
              <Typist.Backspace count={13} delay={1000} />
              <Typist.Delay ms={500} />
              a Fullstack Webdeveloper.
              <Typist.Backspace count={28} delay={1000} />
              <Typist.Delay ms={500} />
              love to code! &hearts;
              <Typist.Backspace count={24} delay={1000} />
              <Typist.Delay ms={1000} />
              <a href="mailto:nico.schunke@softwise.de">Hire me!</a>
            </Typist>
          </h1>
        </div>
      );
    }
  }

export default WelcomeTyping;
import React from "react"
import { Link } from 'react-scroll'
import ReactGA from 'react-ga';

export default class Menu extends React.Component {
    constructor() {
      super();
  
      this.state = {
        menuVisible: true,
        styles: {
            transition: 'height 0.5s linear',
            height: '40px'
        }
      };
    }
  
    componentDidMount() {
      window.addEventListener('scroll', this.onScroll, false);
    }
  
    componentWillUnmount() {
      window.removeEventListener('scroll', this.onScroll, false);
    }
  
    onScroll = e => {
      if (window.innerHeight * 0.9 < window.scrollY) {
        this.setState({ menuVisible: false });
      } else {
        this.setState({ menuVisible: true });
      }
    }

    onClick = (target) => {
      ReactGA.event({
        category: 'Link',
        action: target
      });

      // console.log('ON_CLICK_LINK', abc);
    }
  
    render() {
      return (
        <nav id="nav-wrap" style={{ height: this.state.menuVisible ? '48px' : '0px' }}>
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
          <a className="mobile-btn" href="#hide" title="Hide navigation">Hide navigation</a>
          <ul id="nav" className="nav" style={{ display: this.state.menuVisible ? 'inline-block' : 'none' }}>
            <li><Link to="home" onClick={this.onClick('home')} spy={true} smooth={true} duration={500}>Home</Link></li>
            <li><Link to="about" onClick={this.onClick('about')} spy={true} smooth={true} duration={500}>About</Link></li>
            <li><Link to="contact" onClick={this.onClick('contact')} spy={true} smooth={true} duration={500}>Contact</Link></li>
            <li><Link to="resume" onClick={this.onClick('resume')} spy={true} smooth={true} duration={500}>Resume</Link></li>
            {/*      <li><a className="smoothscroll" href="#portfolio">Works</a></li>
                <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li>*/}
          </ul>
        </nav>
      )
    }
  }
import React, { Component } from "react"
import reactStringReplace from 'react-string-replace';

import Tags from '../Tags'

class RoleDescription extends Component {
  getHighlightedRoleTitle = (roleTitle) => {
    const highlightedRoleTitle = reactStringReplace(roleTitle, /(<b>.*<\/b>)/gi, (match, i) => {
      const highlightedPart = match.replace('<b>', '').replace('</b>', '');
      return (
        <b key={Math.floor(Math.random() * Math.floor(1000))}>{highlightedPart}</b>
      )
    });

    return <u>{highlightedRoleTitle}</u>;
  }

  render() {
    return <li key={Math.floor(Math.random() * Math.floor(1000)).toString()}>
      <p>
        {this.getHighlightedRoleTitle(this.props.roleTitle)} {this.props.roleDescription}
      </p>
      <Tags tags={this.props.tags} />
    </li>
  }
}

export default RoleDescription;

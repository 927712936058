import React, { Component } from "react"
import reactStringReplace from 'react-string-replace';

class Company extends Component {
  linkify = (detail) => {
    const replacedDetail = reactStringReplace(detail, /(\(.*\)\[.*\])/gi, (match, i) => {
      const linkName = match.match(/\((.*)\)/gi)[0].replace('(', '').replace(')', '');
      const linkTarget = match.match(/\[(.*)\]/gi)[0].replace('[', '').replace(']', '');
      return (
        <a key={i} href={linkTarget}>{linkName}</a>
      )
    });

    return replacedDetail;
  }

  render() {
    return <div>
      <u>{this.props.data.timeRange}</u>{" "}
      <a href={this.props.data.company.url} rel="noopener noreferrer" target="_blank">
        {this.props.data.company.name}
      </a>
      , {this.props.data.company.location}
      <a href={this.props.data.company.url} rel="noopener noreferrer" target="_blank">
        {this.props.children}
      </a>
      <div className="business-description">
        {this.props.data.project.title}
        <ul>
          {this.props.data.project.details.map((detail, index) => (
            <li key={index}>{this.linkify(detail)}</li>
          ))}
        </ul>
      </div>
    </div>;
  }
}

export default Company;

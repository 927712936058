import React from "react"

import WorkItemCleverbridge2 from './items/Cleverbridge2'
import WorkItemCleverbridge1 from './items/Cleverbridge1'
import WorkItemMeinestadt from './items/Meinstadt'

// import CompanyLogoMs from "../../images/LogoMs"
// import CompanyLogoMotex from "../../images/LogoMotex"
// import CompanyLogoDigiOnline from "../../images/LogoDigiOnline"
// import CompanyLogoBetaWeb from "../../images/LogoBetaWeb"
// import CompanyLogoAktiveHyp from "../../images/LogoAktiveHyp"

export default () => (
  <div className="row work">
    <div className="three columns header-col">
      <h1>
        <span>Work</span>
      </h1>
    </div>
    <div className="nine columns main-col">
      <WorkItemCleverbridge2 />
      <WorkItemCleverbridge1 />
      <WorkItemMeinestadt />

      {/* POI
      Weiterbildung       */}

      {/* <div className="row item work-item">
        <div className="two columns company-logo-column">
          <CompanyLogoAktiveHyp />
        </div>
        <div className="ten columns">
          <h3>aktivehyp® e.K., Köln</h3>
          <p className="info">
            <em className="date">2011</em>
            <ul>
              <li>
                www.aktivehyp.de - Anpassung / Erweiterung der Webpräsenz für
                Immobilienfinanzierung
              </li>
            </ul>
          </p>
        </div>
      </div>


      <div className="row item work-item">
        <div className="two columns company-logo-column">
          <CompanyLogoBetaWeb />
        </div>
        <div className="ten columns">
          <h3>beta-web GmbH, Bonn</h3>
          <p className="info">
            <em className="date">2011</em>
            <ul>
              <li>
                probaskets.de - Anpassung / Erweiterung der Profi Basketball
                Bundesliga Telekom Baskets Webpräsenz
              </li>
            </ul>
          </p>
        </div>
      </div>


      <div className="row item work-item">
        <div className="two columns company-logo-column">
          <CompanyLogoDigiOnline />
        </div>
        <div className="ten columns">
          <h3>Digi Online GmbH, Köln</h3>
          <p className="info">
            <em className="date">2010 - 2011</em>
            <ul>
              <li>lerncoachies.de - Cornelsen Verlag</li>
              <li>lehreronline.de</li>
              <li>...</li>
            </ul>
          </p>
        </div>
      </div>


      <div className="row item work-item">
        <div className="two columns company-logo-column">
          <CompanyLogoMotex />
        </div>
        <div className="ten columns">
          <h3>
            MOTEX - Mode-Textil-Service Logistik und Management GmbH, Hörselgau
          </h3>
          <p className="info">
            <em className="date">2005 - 2009</em>
            <ul>
              <li>Intranet - interne Logistikapplikation</li>
              <li>...</li>
            </ul>
          </p>
        </div>
      </div> */}
    </div>
  </div>
)
